@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   font-family: "Montserrat", sans-serif;
// }

// body {
//   background-color: #c9d6ff;
//   background: linear-gradient(to right, #e2e2e2, #c9d6ff);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   height: 100vh;
//   background-size: contain;
// }
.icon-images {
  height: 70px;
  width: auto;
}

.container {
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 550px;
  margin: auto;
}

.container p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
  font-family: "ZemestroStd-Regular";
}

.container span {
  font-size: 12px;
}

.container a {
  color: #333;
  font-size: 13px;
  text-decoration: none;
  margin: 15px 0 10px;
  text-align: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.container button {
  background: linear-gradient(to right, #e52817, #a01d18);
  color: #fff;
  font-size: 12px;
  padding: 8px 25px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 10px;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.login-form-container .ant-image {
  display: flex;
  margin: 0 auto;
}

.login-form-container {
  width: 90%;
}

.form-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.6s ease-in-out;
}

.ant-input-password {
  padding: 10px;
}

.container input {
  border: none;
  margin: 0px 0px !important;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  border: 1px solid #dedede !important;
}

.container .password input {
  border: unset !important;
}

.form-container.sign-in {
  left: 0;
  width: 50%;
  z-index: 2;
  padding: 10px;
}

#Signup-Form {
  width: 80%;
}

.container.active .form-container.sign-in {
  transform: translateX(100%);
}

.form-container.sign-up {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.active .form-container.sign-up {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@keyframes move {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.toggle-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 80px 0 0 80px;
  z-index: 1000;
}

.container.active .toggle-container {
  transform: translateX(-100%);
  border-radius: 0 80px 80px 0;
}

.toggle {
  background: linear-gradient(to right, #e52817, #a01d18);
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translate(0);
  transition: all 0.6s ease-in-out;
}

.container.active .toggle {
  transform: translateX(50%);
}

.toggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transition: all 0.6s ease-in-out;
}

.toggle-left {
  transform: translateX(-200%);
}

.container.active .toggle-left {
  transform: translateX(0);
}

.toggle-right {
  right: 0;
  transform: translateX(0);
}

.container.active .toggle-right {
  transform: translateX(200%);
}

.usps {
  display: flex;
}

.usps img {
  width: 90px;
  height: 80px;
}

.login-with-alfa {
  width: 100%;
  display: flex;
  color: #fff !important;
  font-family: "ZemestroStd-Regular";
  background: linear-gradient(to right, #e52817, #a01d18);
  color: #fff;
  font-size: 12px;
  padding: 8px 25px;
  border: 1px solid transparent;
  border-radius: 8px;
  // font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .or {
  margin: 0;
  font-family: "ZemestroStd-Regular";
  text-align: center;
}

body .container button:hover span {
  color: #fff;
}

.container input::placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.alfa-sign-in .anticon svg {
  color: #a6a6a6;
}

.alfa-sign-in .password {
  margin-bottom: 0;
}

.LoginPage {
  position: relative;
  height: 100vh;
  background-image: url("../../assets//images/finalbackground.jpg");
  background-size: contain;
}

.LoginPage > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Responsive Design for Mobile Screens */
@media only screen and (max-width: 768px) {
  .icon-images {
    height: 50px;
    width: auto;
  }

  .container a {
    font-size: 11px;
  }

  body .login-with-alfa {
    padding: 8px 5px;
    margin-bottom: 0;
  }

  #container {
    width: 100%;
    padding: 20px;
    margin: auto;
    border-radius: 20px;
    height: 100vh;
    padding: 0;
  }

  h1,
  h2,
  h3,
  p {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
  }

  .form-container {
    flex-direction: column;
    align-items: center;
  }

  .toggle-container {
    flex-direction: column;
    align-items: center;
    border-radius: 80px 0 0 80px;
  }

  .toggle .toggle-panel {
    margin: 10px 0;
    padding: 0px 10px;
  }

  .toggle-btn {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    width: 100%;
    margin: 10px 0;
  }

  .select-selected,
  .select-items div {
    width: 100%;
    box-sizing: border-box;
  }

  .usps {
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    gap: 10px;
  }

  .usps img {
    width: 50px;
    height: auto;
  }

  .container button {
    padding: 8px 20px;
  }

  .login-form-container {
    width: 100%;
  }
}
